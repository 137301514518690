import {Call, Falsy, useCall} from "@usedapp/core";
import {ContractNames} from '../contracts/contract-addresses'
import {useContract} from "./useContract";
import {ICustomCallOpts} from "./customCall.interface";
import {Contract} from "@ethersproject/contracts";

/** @dev Similar to useContractFunction but for read-methods. */
export const useBuildCallObj: (contractName: string, method: string, args?: any[], opts?: ICustomCallOpts) => Call<Contract, string> =
    (contractName: string, method: string, args?: any[], opts?: ICustomCallOpts) => {
        return {
            contract: useContract(contractName, opts), // instance of called contract
            method, // Method to be called
            args: args ?? [], // Method arguments - address to be checked for balance
        }
    }