import {Contract} from "@ethersproject/contracts";
// import {Contract} from '@usedapp/core/node_modules/@ethersproject/contracts'
import contractAddresses, {getRecommendedChainId, getSupportedChain} from "../contracts/contract-addresses";
import {utils} from "ethers";
import {useEthers} from "@usedapp/core";
import {ICustomCallOpts} from "./customCall.interface";

export function useAbi(contractName: string): utils.Interface {
    return new utils.Interface(require("../contracts/abi/"+contractName+".json"));
}

export function useContract(contractName: string, opts?: ICustomCallOpts): Contract {
    let chainId = useEthers().chainId || getRecommendedChainId()
    if (opts?.chainId) {
        // override
        chainId = opts?.chainId;
    }

    return new Contract(contractAddresses[contractName][chainId] ?? '0xa4a6ad0546363d093a6eef1eabd31255a49b7635', // dummy address to avoid crashing (frontend should prevent any network interaction anyway when wrong network)
        useAbi(contractName));
}