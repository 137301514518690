import {Call, Falsy, useCall, useCalls} from "@usedapp/core";
import {Contract} from "@ethersproject/contracts";

export function useMultiCallRead(calls: (Call<Contract, string>)[]): any[] {
    const results = useCalls(calls) ?? []
    results.forEach((result, idx) => {
        if(result && result.error) {
            console.error(`Error encountered calling view-function on ${calls[idx]?.contract.address}: ${result.error.message}`)
        }
    })
    return results.map(result => result?.value?.[0])
}