import Carousel from "react-material-ui-carousel";
import imgNft2 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/2.jpg"
import imgNft3 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/3.jpg"
import imgNft6 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/6.jpg"
import imgNft12 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/12.jpg"
import imgNft20 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/20.jpg"
import imgNft25 from "../../shared-libs/wavect-styles/assets/artwork/previewNFTs/tiny/25.jpg"
import {useMediaQuery} from "@mui/material";

export const OfficialNFTCarousel = () => {

    const items = [{src: imgNft2, id: 2},
        {src: imgNft3, id: 3}, {src: imgNft6, id: 6}, {src: imgNft12, id: 12},
        {src: imgNft20, id: 20}, {src: imgNft25, id: 25}]

    const isMobile = useMediaQuery(`(max-width: 575px)`);
    const imageSize = isMobile ? "90%" : 500;

    return <Carousel autoPlay={true} animation="fade" indicators={true} duration={500} stopAutoPlayOnHover={true}
                     navButtonsAlwaysVisible={true} navButtonsAlwaysInvisible={false} cycleNavigation={true}
                     fullHeightHover={true} swipe={true} sx={{width: "100%", textAlign: 'center'}}>
        {
            items.map((item, index) => {
                return <img src={item.src} alt={`Ernesto's Friends ${item.id}`} key={index} width={imageSize} height={imageSize} className="w-img-slideshow" />
            })
        }
    </Carousel>
}