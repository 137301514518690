import {Arbitrum, Chain, Goerli, Rinkeby, Ropsten} from "@usedapp/core";
import {getDefaultProvider, providers} from "ethers";
import {BaseProviderFactory} from "@usedapp/core/src/constants/type/Config";

const RpcProviders = {
    [Goerli.chainId]: [
        getDefaultProvider('goerli'),
        'https://rpc.ankr.com/eth_goerli',
        'https://goerli.infura.io/v3/c44298d3d7e247449c5c1d442589d59c',
    ],
    [Arbitrum.chainId]: [
      //getDefaultProvider('arbitrum'),
      'https://1rpc.io/arb',
      'https://endpoints.omniatech.io/v1/arbitrum/one/public',
      'https://rpc.ankr.com/arbitrum',
      'https://arbitrum.blockpi.network/v1/rpc/public'
    ],
}

const getRandomProvider = (providers: any): string | providers.BaseProvider | BaseProviderFactory => {
    return providers[Math.floor(Math.random()*providers.length)]
}

/** @dev This is just to do some load balancing between RPC providers client-side */
export const getRandomProviderOfChain = (network: Chain): string | providers.BaseProvider | BaseProviderFactory => {
    if (!RpcProviders.hasOwnProperty(network.chainId)) throw new Error('Invalid chainId, not supported')
    return getRandomProvider(RpcProviders[network.chainId])
}
