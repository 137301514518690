import i18n from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const getLangISO2 = (i18n: any) => {
    // console.log(i18n.language.slice(3, 5))
    return i18n.language.slice(3, 5);
}

const DETECTION_OPTIONS = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lang'
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        detection: DETECTION_OPTIONS,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    menu: {
                        home: "Courses",
                        collection: "NFT Collection",
                        whoWeAre: "Our Team",
                    },
                    registration: {
                        intro: "Register now to one of our courses about blockchain and NFTs.",
                        content: "We regularly offer blockchain courses. No previous knowledge is required. 100% free.",
                        explanation: "Our courses are 100% for free. Your registration will be done via one of our forms, in which you can add your likes and preferences." +
                            " We will then try to focus on your preferences. " +
                            "This way, the course gets more personal. We'll talk about the topics you're actually interested in.",
                        watchMore: "Why not take a look at our Youtube channel, so you can get a first impression? We have tons of talks and material there. You can find our channels below."
                    },
                    common: {
                        moreInformation: "More information",
                        registerNow: "Register now",
                        becomeSponsor: "Become a Sponsor",
                        registerNowOtherLanguage: "Click here for german course",
                        registerNoOtherLanguage: "Only in english",
                        ourSocials: "Our Socials",
                        backToTop: "I'll bring you back up",
                        upcomingEvents: "Online courses",
                        creators: 'Creators',
                        creatorsAbout: "Get to know us",
                        creatorsIntro: "Creating the Web3 of tomorrow",
                        createSlogan: 'Creating the',
                        createSlogan2: 'of tomorrow',
                        seeMore: "See More",
                        readMore: "Read more",
                        joinDiscord: "Join Discord Server",
                        whatWeAreBuilding: "What we are building",
                        latestCreations: "Some of our latest creations and projects",
                        wrongNetwork: "Unsupported network",
                        switchNetwork: "Switch network",
                        connect: "Connect",
                        ctaBusinessCall: "Business? Schedule meeting",
                        ourProjects: "Our projects",
                        opensea: "View on Opensea"
                    },
                    ernestoSpeaks: {
                        one: 'Junk food over Web3? Always.',
                        two: 'I may not understand Web3, but I sure understand junk food.',
                        three: 'Web3? Never heard of it. Pass the chips.',
                        four: 'Why code when you can chow down on junk food?',
                        five: 'I may not be a blockchain expert, but I am a junk food aficionado.',
                        six: 'Junk food is my true decentralized love.',
                        seven: 'I\'m Ernesto, the Chief Meme Officer.',
                        eight: 'Web3? More like Web-3 seconds until I devour this junk food.',
                        nine: 'Junk food is my non-fungible token of love.',
                        ten: 'I may not be a Bitcoin miner, but I can mine a bag of chips like no one\'s business.',
                    },
                    networkSwitchDialog: {
                        heading: "Switch Network",
                        ctaMsgSwitch: "Select a chain to switch the network.",
                        ctaMsgUnsupportedSwitch: "Network not supported yet. Please choose a chain below.",
                        tabMainnet: "Mainnet",
                        tabTestnet: "Testnet",
                        close: 'Close',
                    },
                    nftPage: {
                        officialCollection: "Official NFT",
                        officialCollectionName: "Ernesto's Friends 🐌",
                        wavectNFTIsA: "The somewhat different",
                        utility: "Utility NFT",
                        utilityVote: "Decide with others & vote",
                        utilityProducts: "Get discounts for upcoming products",
                        utilityIP: "Own non-commercial rights on the IP",
                        utilityMedia: "Exclusive education content",
                        utilityMore: "and more to come!",
                        form: {
                            heading: "Mint NFT",
                            lblPricePerNFT: "Price per Collectible:",
                            lblFree: "free",
                            lblGetWhitelisted: "Get whitelisted",
                            lblConnectWallet: "Connect Wallet",
                            lblFiatRampModal: 'Use credit card',
                            remarkNetworkFeeWhenFree: "You only have to pay for the network fee which should be around a few Dollars. We don't get anything from this money.",
                            errMaxWallet: "You already minted all NFTs.",
                            errNotWhitelistedInPrivateSale: "Private mint ongoing - Only selected wallets are allowed to mint. Feel free to apply for a spot above.",
                            errUnsupportedChain: "Network not supported.",
                            crossMintViewCustodialWallet: "View custodial wallet",
                        },
                        btnWalletMint: "Mint with wallet",
                        headingNftPreview: "Collectible Preview",
                    },
                    contact: {
                        showEducation: "Show Courses",
                        showCollection: "Show Collection",
                        showWavect: "Head over to Wavect.io",
                        typeEducational: "Educational",
                        typeProduct: "Product",
                        typeCollection: "Collection",
                        typeAgency: "Agency",
                        aboutEducationHeader: "Online Courses",
                        aboutEducation: 'We teach you Blockchain, Smart Contracts and DApps. The courses are for free and there is no knowledge prior required! You can add your preferences and we\'ll create a course that answers your questions. Web3 is just one click away. Join now!',
                        aboutWavect: 'We are specialized on Blockchain-related work such as Smart-Contracts, DApps on a variety of different chains, technical project-management, advisory / consultation and do technical workshops.',
                        aboutNFT: 'Wavect will get his own NFT Collection! We will soon be launching Ernesto\'s Friends. Follow Wavect on LinkedIn for more information. First come, first serve. We\'ll giveaway the first 20 NFTs.'
                    },
                    courses: {
                        common: {
                            otherLanguage: "Auch auf Deutsch verfügbar"
                        },
                        web3ForEntries: {
                            title: "Web3 for 10-15 year olds",
                            content: 'We will show you what\'s behind Blockchains and NFTs. With easy to understand examples and illustrations, the entrance to Web3 is one step away. Managing wallets, tokens, the metaverse and many more exciting topics.',
                        },
                        web3ForWomen: {
                            title: "Web3 for Women",
                            content: "In this course we will focus on security in Web3. We will show you how to keep your wallets and keys safe, how to detect malicious smart contracts and we talk about blockchain security in general."
                        }
                    }
                }
            },
            de: {
                translation: {
                    menu: {
                        home: "Kurse",
                        collection: "NFT Kollektion",
                        whoWeAre: "Unser Team",
                    },
                    registration: {
                        intro: "Melde dich jetzt für kostenlose Kurse rund um das Thema Blockchain und NFTs an.",
                        content: "Wir bieten laufend neue Kurse an, die keine Vorkentnisse benötigen.",
                        explanation: "Unsere Kurse sind 100% kostenlos. Eine Anmeldung erfolgt über ein separates Anmeldeformular. " +
                            "Durch das Anmeldeformular schneiden wir das Programm gezielt auf die Teilnehmer zu. " +
                            "Dadurch wird dein Kurserlebnis noch persönlicher und wir können über Themen sprechen, die dich interessieren!",
                        watchMore: "Schau doch mal bei unserem Youtube Channel vorbei, um dir einen ersten Eindruck zu verschaffen. Du findest unsere Kanäle unten."
                    },
                    common: {
                        moreInformation: "Mehr Informationen",
                        registerNow: "Jetzt anmelden",
                        becomeSponsor: "Sponsor werden",
                        registerNowOtherLanguage: "Auch auf Englisch verfügbar",
                        registerNoOtherLanguage: "Nur Englisch verfügbar",
                        ourSocials: "Social Media",
                        backToTop: "Nach oben",
                        upcomingEvents: "Unsere Blockchain Kurse",
                        creators: 'Unser Team',
                        creatorsAbout: "Lerne uns kennen",
                        createSlogan: 'Für ein',
                        createSlogan2: 'von morgen',
                        seeMore: "Mehr anzeigen",
                        joinDiscord: "Discord beitreten",
                        readMore: "Mehr lesen",
                        whatWeAreBuilding: "Aktuelle Projekte",
                        latestCreations: "An was wir arbeiten",
                        wrongNetwork: "Falsches Netzwerk",
                        switchNetwork: "Netzwerk wechseln",
                        connect: "Verbinden",
                        ctaBusinessCall: "Unternehmen? Meeting planen",
                        ourProjects: "Unsere Projekte",
                        opensea: "Auf Opensea"
                    },
                    networkSwitchDialog: {
                        heading: "Netzwerk wechseln",
                        ctaMsgSwitch: "Zum Wechseln ein Netzwerk auswählen.",
                        ctaMsgUnsupportedSwitch: "Netzwerk noch nicht unterstützt. Wähle ein anderes.",
                        tabMainnet: "Mainnet",
                        tabTestnet: "Testnet",
                        close: 'Schliessen',
                    },
                    ernestoSpeaks: {
                        one: 'Junkfood über Web3? Immer.',
                        two: 'Web3? Keine Ahnung, aber ich weiß genau, wie man eine Tüte Chips vernichtet.',
                        three: 'Web3? Kenne ich nicht. Gebt mir die Chips.',
                        four: 'Warum programmieren, wenn man Junkfood essen kann?',
                        five: 'Ich bin vielleicht kein Blockchain-Experte, aber ich bin ein Junkfood-Aficionado.',
                        six: 'Junkfood ist meine wahre dezentralisierte Liebe.',
                        seven: 'Ich bin Ernesto, der Chief Meme Officer.',
                        eight: 'Web3? Eher wie Web-3 Sekunden, bis ich dieses Junkfood verschlinge.',
                        nine: 'Junkfood ist mein NFT der Liebe.',
                        ten: 'Ich bin vielleicht kein Bitcoin-Miner, aber ich kann eine Tüte Chips wie kein anderer abbauen.',
                    },
                    nftPage: {
                        officialCollectionName: "Ernesto's Friends 🐌",
                        officialCollection: "Offizielle NFT Kollektion",
                        wavectNFTIsA: "Das etwas andere ",
                        utility: "Utility NFT",
                        utilityVote: "Entscheide mit Anderen & stimme ab",
                        utilityProducts: "Erhalte Rabatte auf zukünftige Produkte",
                        utilityIP: "Nicht-kommerzielle Nutzungsrechte am NFT",
                        utilityMedia: "Exklusiver Zugang zu Educational Content",
                        utilityMore: "& weitere im Laufe der Zeit!",
                        form: {
                            heading: "NFT minten",
                            lblPricePerNFT: "Preis pro Stück:",
                            lblFree: "kostenlos",
                            lblGetWhitelisted: "Whitelist anfragen",
                            lblConnectWallet: "Wallet verbinden",
                            lblFiatRampModal: 'Kreditkarte verwenden',
                            remarkNetworkFeeWhenFree: "Es sind nur die Netzwerkgebühren zu zahlen, welche üblicherweise wenige Euro betragen. Kein Cent davon geht in unsere Taschen.",
                            errMaxWallet: "Du hast bereits alle NFTs gemintet.",
                            errNotWhitelistedInPrivateSale: "Privater Mint aktiv - Nur ausgewählte Wallets können minten. Du kannst eine Whitelist beantragen (siehe oben).",
                            errUnsupportedChain: "Netzwerk nicht unterstützt.",
                            crossMintViewCustodialWallet: "Custodial Wallet ansehen",
                        },
                        headingNftPreview: "Collectible Vorschau",
                        btnWalletMint: "Mit Wallet minten",
                    },
                    contact: {
                        showEducation: "Kurse anzeigen",
                        showCollection: "Kollektion anzeigen",
                        showWavect: "gehe zu Wavect.io",
                        typeEducational: "Weiterbildung",
                        typeProduct: "Produkt",
                        typeCollection: "Kollektion",
                        typeAgency: "Agentur",
                        aboutEducationHeader: "Onlinekurse",
                        aboutEducation: 'Wir unterrichten dir Blockchain, Smart Contracts und den Umgang mit DApps in unserer Bildungsecke. Die Kurse sind kostenlos und es sind meist keine Vorkentnisse nötig!',
                        aboutWavect: 'Wir sind eine Blockchain Agentur mit Fokus auf Smart Contracts, DApps und einer vielzahl von verschiedenen Chains, technischem Projekt Management, Beratung und Workshops ',
                        aboutNFT: 'Wavect wird in Kürze seine eigene limitierte NFT Sammlung erhalten. Die ersten 20 NFTs werden nach dem Prinzip first come first serve kostenlos vergeben.'
                    },
                    courses: {
                        common: {
                            otherLanguage: "Choose english course"
                        },
                        web3ForEntries: {
                            title: "Web3 für 10-15 Jährige",
                            content: 'Wir bringen Dir Web3, Blockchains und NFTs näher. Mit vereinfachten Beispielen und Illustrationen gelingt der Einstieg in die Welt der Blockchains. Die Verwaltung deiner Wallet, das Metaverse, eigene Tokens und viele weitere spannende Bereiche.',
                            registerFor: "Register Now (english course)"
                        },
                        web3ForWomen: {
                            title: "Web3 für Frauen (EN)",
                            content: "In diesem Kurs gehen wir besonders auf Security in Web3 ein. Wir zeigen Dir wie man seine Wallets und Schlüssel sicher verwahrt, wie man schadhafte Smart Contracts erkennt und wie allgemeine Sicherheitsmechanismen auf der Blockchain funktionieren."
                        }
                    }
                }
            }
        }
    });

console.log(i18n.resolvedLanguage)

export default i18n;
