import {paymentMethods, SigninMethods} from "@crossmint/client-sdk-base";
import {memo, useEffect, useState} from "react";
import {t} from "i18next";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WButton from "../../shared-libs/wavect-core/components/WButton/WButton";
import * as React from "react";
import i18n from "../../utils/i18n";

interface IArbFiatRampButtonProps {
    userWallet?: string;
    mintPriceETH?: string;
    amountToMint?: string;
    signature?: string;
    preferredSigninMethod?: SigninMethods;
    environment?: string;
    paymentMethod?: paymentMethods;
}

/*.xmint-btn::part(button) {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.xmint-btn::part(contentParagraph) {
    color: white;
    font-size: 1.25rem;
}*/

const modalID = 'MtPelerinModal'
export const ArbFiatRampButton = memo((props: IArbFiatRampButtonProps) => {

    const [scriptLoaded, setScriptLoaded] = useState(false)

    const {userWallet, amountToMint = '1', mintPriceETH = '0'} = props;
    const totalPrice: string = (parseFloat(mintPriceETH) * parseInt(amountToMint) + 0.05).toString()

    const openModal = () => {
        if (scriptLoaded) {
            const lang: string = i18n.language === 'de' ? 'de' : 'en';

            (window as any).showMtpModal({lang, tabs: ['buy'], bdc: 'ETH', bsc: 'USD', crys: ['ETH'], net: ['arbitrum_mainnet'], nets: ['arbitrum_mainnet'],
                mylogo: 'https://wavect.io/img/logos/webp/logo_combination.webp', primary: '#459da1',
                addr: userWallet, bda: totalPrice});

            const modal = document.getElementById(modalID); //!.getElementsByClassName(iframeModalClassName)[0];
            (modal as any).style.top = '85px';

        } else {
            console.error('FiatOnRamp Script not loaded.')
        }
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://widget.mtpelerin.com/mtp-widget.js";
        script.async = true;
        script.onload = () => setScriptLoaded(true);

        document.body.appendChild(script);
    })

    return <>
        <WButton className="m-t-2 m-b-2" colorType="dark" onClick={openModal}>
            <AccountBalanceIcon/>&nbsp;{t('nftPage.form.lblFiatRampModal')}</WButton>
    </>
})

