import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useTranslation} from "react-i18next";
import {Slider, Tooltip} from "@mui/material";

export default function SmartSuggestions() {
    const {t} = useTranslation();

    const scroll = (id: 'creators' | 'web3Content' | 'creators-section' | 'creators-intro') => {
        const section = document.querySelector('#' + id);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    const marks = [
        {
            value: 0,
            label: 'What is Web3?',
        },
        {
            value: 30,
            label: 'Web3',
        },
        {
            value: 100,
            label: 'Whats your next selection?',
        },
    ];

    return (
        <div id="smart-suggestions-section" className="w-section w-center-center w-column">
            <h1>Smart, Suggestions-based learning is here.</h1>
            <p className="w-p">Watch out for <span className="w-clickable">these highlights</span> - they let you know
                there is either a tooltip, a complete article or a Video about it, to deepen your knowledge.
            This is your learn graph. Explore where it leads you - In the end, you will get a summary
                of everything. Enjoy!</p>
            <Slider defaultValue={30} marks={marks} orientation="horizontal" sx={{'width': '500px', 'marginBottom': "15rem", 'marginTop': '5rem'}}></Slider>
            <h3 className="w-text-start">An Introduction To Web3</h3>
            <p className="w-p">
                This is a high level test of the features we maybe will try to integrate into this
                <Tooltip
                    title="Web3 is an idea for a new iteration of the World Wide Web which incorporates concepts such as decentralization, blockchain technologies, and token-based communities. CLICK TO READ MORE"
                    placement="top">
                    <span className="w-clickable">web3</span>
                </Tooltip>
                course. We will explore a lot of concepts and technologies!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid cumque fuga, ipsa ipsam ipsum laborum
                mollitia quia saepe similique vero. Ex laboriosam maiores nam natus nihil odio, ratione. Assumenda,
                quae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aliquam architecto,
                blanditiis, culpa deserunt ducimus enim eos facilis nihil nisi repellendus reprehenderit sint ullam unde
                voluptatibus! Doloremque magnam molestias nisi. <span className="w-clickable">NFT</span> Lorem ipsum
                dolor sit amet, consectetur adipisicing elit. Ad autem doloremque ea eligendi error eum illum ipsum
                maxime modi, neque quasi velit veniam voluptates. Amet asperiores ducimus nihil tempora voluptas.lorem
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab blanditiis deleniti dolor earum enim facere
                harum illo impedit minus mollitia nihil nostrum officia perferendis quod quos sint totam ullam,
                veritatis! <span className="w-clickable">Blockchain</span> Lorem ipsum dolor sit amet, consectetur
                adipisicing elit. Accusamus animi aspernatur blanditiis culpa doloremque ea natus, optio rem, repellat,
                repudiandae sed soluta tempore? Eius enim est iure omnis perspiciatis sapiente. Lorem ipsum dolor sit
                amet, consectetur adipisicing elit. Dignissimos eaque praesentium quos sit voluptatibus. Animi commodi
                illo nostrum optio possimus? Ab aliquam at dolorem eos <span className="w-clickable">Zero Knowledge</span> incidunt iure, laboriosam nemo praesentium. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, adipisci architecto beatae consequuntur,
                corporis cum debitis doloribus et harum ipsam iure magnam minus nostrum porro quae quas quidem tempore
                voluptatem.

            </p>
        </div>
    );
}
