import React from 'react';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import ContactPage from './pages/ContactPage';
import ImprintPage from "./pages/ImprintPage";
import {ThemeProvider} from "@mui/material";
import {muiTheme} from "./shared-libs/wavect-styles/core/MainTheme"
import EducationalPage from "./pages/EducationalPage";
import './utils/i18n';
import {NFTPage} from "./pages/NFTPage";
import SmartSuggestions from "./pages/SmartSuggestions";
import {Arbitrum, Config, DAppProvider} from "@usedapp/core";
import {getRandomProviderOfChain} from "./utils/randomProvider";
import {supportedChains} from "./contracts/contract-addresses";

 const config: Config = {
     readOnlyChainId: Arbitrum.chainId, // TODO: Also adopt
     readOnlyUrls: {
         [Arbitrum.chainId]: getRandomProviderOfChain(Arbitrum),
     },
     networks: supportedChains,
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <DAppProvider config={config}>
            <ThemeProvider theme={muiTheme}>
                <ResponsiveAppBar/>
                <Routes>
                    <Route path="/" element={<EducationalPage/>}/>
                    {/*<Route path="airdrop-ads" element={<ProductAirdropAds/>}/>*/}
                    <Route path="contact" element={<ContactPage/>}/>
                    <Route path="about-us" element={<ImprintPage/>}/>
                    {/*<Route path="media" element={<MediaContentPage/>}/>*/}
                    <Route path="collection" element={<NFTPage/>}/>
                    <Route path="smart-suggestions" element={<SmartSuggestions/>}/>
                    <Route
                        path="*"
                        element={
                            <main style={{padding: "1rem"}}>
                                <p>404 Page - How'd you get there?</p>
                            </main>
                        }
                    />
                </Routes>
            </ThemeProvider>
        </DAppProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
