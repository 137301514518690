import {Button} from "@mui/material";
import * as React from "react";
import {WButtonInterface} from "./WButton.interface";
import {getColorType} from "../../../wavect-styles/core/common";

function WButton(props: WButtonInterface) {
    return (
        <Button
            disabled={props.disabled}
            className={props.className}
            variant={props.variant ?? 'contained'}
            onClick={() => props.onClick ? props.onClick() : null}
            sx={{
                marginRight: '1rem',
                fontSize: '16px',
                background: getColorType(props.colorType),
                ':hover': {
                    cursor: 'pointer',
                    bgcolor: getColorType(props.colorType),
                    opacity: 0.9
                },
                ...props.sx,
            }}
        >
            {
                props.children
            }
        </Button>
    )
}

export default WButton;
