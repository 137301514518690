import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import {WShowMoreInterface} from "./WShowMore.interface";

export const WShowMore = (props: WShowMoreInterface) => {
    return (
        <div className="w-column w-center-center m-t-3 p-3" onClick={props.click}>
            <h3 className="w-no-spacing">{props.text}</h3>
            <ExpandMoreIcon className="w-icon hoverable moving large"/>
        </div>
    )
}
