import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useTranslation} from "react-i18next";
import kevChris from "../shared-libs/wavect-styles/assets/people/creators.jpg"
import {WShowMore} from "../shared-libs/wavect-core/components/WShowMore/WShowMore";

export default function ContactPage() {
    const {t} = useTranslation();

    const scroll = (id: 'creators' | 'web3Content' | 'creators-section' | 'creators-intro') => {
        console.log('scrolling')
        const section = document.querySelector('#' + id);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return (
        <div id="creators-section">
            <div className="w-section w-clouds-background waves-2 w-center-center" id="creators-intro">
                <Box className="w-center-center column w-100">
                    <h2 className="w-text-center w-no-spacing">{t('common.creators')}</h2>
                    <h3 className="w-text-center w-span">{t('common.creatorsAbout')}</h3>
                    <div>
                        <div onClick={() => scroll('creators')}>
                            <ExpandMoreIcon className="w-icon hoverable moving large"/>
                        </div>
                    </div>
                </Box>
            </div>
            <div className="w-section w-clouds-background waves-3 m-t-4 w-center-center" id="creators">
                <Box className="w-creator-body">
                    <h1 className="w-text-center w-no-spacing-bottom">{t('common.createSlogan')} <span
                        className="w-underline-marker">Web3</span> {t('common.createSlogan2')}</h1>
                    <div>
                        {/*<img src={kevChris} alt=""/>*/}
                    </div>
                    <WShowMore click={() => scroll('web3Content')} text={t('common.ourProjects')}/>
                </Box>
            </div>
            <div className="w-section w-clouds-background w-row waves-7" id="web3Content"
                 style={{'position': 'relative'}}>
                <div className="w-contact-product-section-header">
                    <h1 className="w-no-spacing">{t('common.whatWeAreBuilding')}</h1>
                    <h3 className="w-no-spacing w-align-left w-span">{t('common.latestCreations')}</h3>
                </div>
                <div className="w-row w-flex-start" id="projects-card-section">
                    <div className="w-card step-1">
                        <h2 className="w-card-header">{t('contact.aboutEducationHeader')}</h2>
                        <span className="w-tag">{t('contact.typeEducational')}</span>
                        <p className="w-card-body">{t('contact.aboutEducation')}</p>
                        <div className="w-card-bottom">
                            <a className="w-link-as-button" href="/">{t('contact.showEducation')}</a>
                        </div>
                    </div>
                    <div className="w-card step-2">
                        <h2 className="w-card-header">Ernesto's Friend</h2>
                        <span className="w-tag">{t('contact.typeCollection')}</span>
                        <p className="w-card-body">{t('contact.aboutNFT')}</p>
                        <div className="w-card-bottom">
                            <a className="w-link-as-button" href="/collection">{t('contact.showCollection')}</a>
                        </div>
                    </div>
                    <div className="w-card step-3">
                        <h2 className="w-card-header">Wavect</h2>
                        <span className="w-tag">{t('contact.typeAgency')}</span>
                        <p className="w-card-body">
                            {t('contact.aboutWavect')}
                        </p>
                        <div className="w-card-bottom">
                            <a target="_blank" className="w-link-as-button" rel="noreferrer"
                               href="https://wavect.io">{t('contact.showWavect')}</a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="w-section w-clouds-background w-row waves-4">*/}
            {/*    <div className="w-section w-center-center w-column">*/}
            {/*        <h1>Kickstart your project</h1>*/}
            {/*        <a className="gumroad-button m-t-3" href="https://wavect.gumroad.com/l/web3-strategy-blockchain-need"*/}
            {/*           data-gumroad-overlay-checkout="true">Buy on</a>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div className="w-mobile-bottom-info" style={{'display': "none"}}>
                <div className="m-t-4">
                    <ExpandLessIcon onClick={() => scroll('creators-section')} className="w-icon large"/>
                </div>
                <span className="back-to-start">Back to Top</span>
            </div>

        </div>
    );
}
