import {OfficialNFTMintBtn} from "../OfficialNFTMintBtn/OfficialNFTMintBtn";
import {CrossMintPayBtn} from "../Crossmint/CrossmintPayButton";
import * as React from "react";
import {getOfficialNFTSignature} from "../../utils/signature";
import {useMultiCallRead} from "../../hooks/useMultiCallRead";
import {useBuildCallObj} from "../../hooks/useBuildCallObj";
import {ContractNames, getSupportedChain} from "../../contracts/contract-addresses";
import {BigNumber, ethers} from "ethers";
import {Chain, useEthers} from "@usedapp/core";
import {Dispatch, SetStateAction, useState} from "react";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ApprovalIcon from '@mui/icons-material/Approval';
import {t} from "i18next";
import {WSwitchNetworkDialog} from "../../shared-libs/wavect-core/components/WSwitchNetworkDialog/WSwitchNetworkDialog";
import PublicOffOutlinedIcon from "@mui/icons-material/PublicOffOutlined";
import WButton from "../../shared-libs/wavect-core/components/WButton/WButton";
import {ArbFiatRampButton} from "../ArbFiatRamp/ArbFiatRampButton";

export interface IOfficialNFTMintFormProps {
    setNotify: Dispatch<SetStateAction<any>>;
}

export const OfficialNFTMintForm = (props: IOfficialNFTMintFormProps) => {
    const {account, activateBrowserWallet, chainId} = useEthers()
    const signature: string = getOfficialNFTSignature(account)
    const {setNotify} = props;

    const [showSwitchNetworkDialog, setShowSwitchNetworkDialog] = useState(false);
    const supportedChain: Chain | undefined = getSupportedChain(chainId ?? -1)

    const amountMint: string = '1' // TODO: Make configurable by user
    const accountNonNull: string = account ?? ethers.constants.AddressZero;

    const [/*nftBalance, */isBelowMaxWallet, isPublicSale, isWhitelisted, /*availableTokenCount,*/ mintPriceWei] = useMultiCallRead([
        //useBuildCallObj(ContractNames.OfficialNFT, 'balanceOf', [account]),
        useBuildCallObj(ContractNames.OfficialNFT, 'isBelowMaxWallet', [accountNonNull, amountMint]),
        useBuildCallObj(ContractNames.OfficialNFT, 'publicSaleEnabled', []),
        useBuildCallObj(ContractNames.OfficialNFT, 'isWhitelisted', [accountNonNull, signature]),
        //useBuildCallObj(ContractNames.OfficialNFT, 'availableTokenCount', []),
        useBuildCallObj(ContractNames.OfficialNFT, 'mintPrice', []),
    ])

    console.error(accountNonNull, signature)

    const isAllowedToMint: boolean = isBelowMaxWallet && (isPublicSale ? true : isWhitelisted)
    const mintPriceETH: BigNumber = ethers.utils.parseEther(mintPriceWei?.toString() ?? '0')
    const isFreeMint: boolean = mintPriceETH.eq('0')

    let errorMsg: string | undefined = undefined;
    if (account) {
        if (!supportedChain) errorMsg = t('nftPage.form.errUnsupportedChain').toString()
        else if (!isBelowMaxWallet) errorMsg = t('nftPage.form.errMaxWallet').toString()
        else if (!isPublicSale && !isWhitelisted) errorMsg = t('nftPage.form.errNotWhitelistedInPrivateSale').toString()
    }

    return <>
        <div className="w-right-section">

            <div className='w-card w-auction'>

                <h2 className="w-no-spacing w-white">{t('nftPage.form.heading')}</h2>

                <span className="w-card-separator"></span>

                {/*<p className="w-white w-text-center p-b-4">
                                {`${availableTokenCount?.toString()}`} pieces left
                        </p>
                            <p className="w-white w-text-center p-b-2">
                        */}


                <div className="w-full-width">
                    <p className="w-white w-text-center">
                        {t('nftPage.form.lblPricePerNFT')}&nbsp;
                        <b>{isFreeMint ? t('nftPage.form.lblFree') : `${mintPriceETH} ETH`}</b>
                    </p>

                    {(account && (isPublicSale || isWhitelisted) &&
                            <OfficialNFTMintBtn
                                setNotify={setNotify}
                                isAllowedToMint={isAllowedToMint} amount={amountMint}
                                account={account}
                                signature={signature}
                                mintPriceWei={mintPriceWei}/>)

                        || (account && !supportedChain &&
                            <WButton className="m-t-2 m-b-2" colorType="danger"
                                     onClick={() => setShowSwitchNetworkDialog(true)}>
                                <PublicOffOutlinedIcon/>&nbsp;{t('common.switchNetwork')}</WButton>)

                        || (account && !isWhitelisted &&
                            <a href="https://kevinriedl.typeform.com/to/uKXVrEyO" target="_blank" rel="noreferrer">
                                <WButton className="m-t-2 m-b-2"
                                         colorType="dark"><ApprovalIcon/>&nbsp;{t('nftPage.form.lblGetWhitelisted')}
                                </WButton></a>)

                        || (<WButton className="m-t-2 m-b-2" colorType="dark" onClick={activateBrowserWallet}>
                            <AccountBalanceWalletIcon/>&nbsp;{t('nftPage.form.lblConnectWallet')}</WButton>)}

                    {/*Only supports ETH, BSC, Polygon, etc. <CrossMintPayBtn amountToMint={amountMint} mintPriceETH={mintPriceETH.toString()}
                                     userWallet={account}/>*/}
                    <ArbFiatRampButton amountToMint={amountMint} mintPriceETH={mintPriceETH.toString()}
                                     userWallet={account}/>

                    {!errorMsg && isFreeMint &&
                        <p className="w-small-text w-white">{t('nftPage.form.remarkNetworkFeeWhenFree')}
                            &nbsp;<a href="https://www.youtube.com/watch?v=3ehaSqwUZ0s" rel="noreferrer"
                                     target="_blank">» {t('common.readMore')}</a></p>}
                    {errorMsg && <p className="w-small-text w-error ">{errorMsg}</p>}

                </div>
            </div>

            {/*
                        nftBalance?.gt(0) &&

                        <div className="w-card mint-success">
                            <h2 className="w-white w-no-spacing">You've received your NFT <br/> 🥰</h2>
                            {/*<p>Show Dashboard</p>

                            <div onClick={() => scroll('dashboard-area')}>
                                <ExpandMoreIcon className="w-icon hoverable moving large w-white"/>
                            </div>}
                        </div>
                    */}
        </div>

        <WSwitchNetworkDialog
            currentChainSupported={!!supportedChain}
            show={showSwitchNetworkDialog}
            setShow={setShowSwitchNetworkDialog}/>
    </>
}