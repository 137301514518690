import * as React from "react";
import {Alert, Box, Snackbar} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {memo, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";
import {OfficialNFTMintForm} from "../components/OfficialNFTMintForm/OfficialNFTMintForm";
import {OfficialNFTCarousel} from "../components/OfficialNFTCarousel/OfficialNFTCarousel";

export const NFTPage = memo(() => {
    let [notify, setNotify] = useState({msg: '', severity: 'error'});

    const scroll = (id: string) => {
        const section = document.querySelector('#' + id);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    const {t} = useTranslation();

    return <div className="w-100" id="mainPage">
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={!!notify.msg} autoHideDuration={3000}>
            <Alert variant="filled" severity={notify.severity as AlertColor}>{notify.msg}</Alert>
        </Snackbar>

        <div className="w-nft-man"></div>

        <Box className='w-column w-fit-content'>
            <div className="w-section w-clouds-background waves-6 w-center-center column starter">

                <h2 className="w-main-intro-header"> {t('nftPage.officialCollectionName')}</h2>
                <h3 className="w-sub-text m-1">{t('nftPage.officialCollection')}</h3>
                <a className="w-discord-button" target="_blank"
                   href="https://opensea.io/collection/ernesto-s-friends-arbitrum">{t('common.opensea')}</a>

                <div style={{"marginTop": "-1rem"}}>
                    <div onClick={() => scroll('utility-area')}>
                        <ExpandMoreIcon className="w-icon hoverable moving large"/>
                    </div>
                </div>
            </div>

            <h1 id="utility-area" className="w-main-page-header p-t-2  w-no-spacing-bottom">{t('nftPage.wavectNFTIsA')}
                <span className="w-highlight w-underline-marker"> {t('nftPage.utility')}</span></h1>

            <div className="w-utility-container w-section w-clouds-background waves-7 w-center-center">
                <div className="w-left-section">
                    <p className="w-utility-item"><span>🗳️</span>{t('nftPage.utilityVote')}</p>
                    <p className="w-utility-item"><span>💰</span>{t('nftPage.utilityProducts')}</p>
                    <p className="w-utility-item"><span>⚖️</span>{t('nftPage.utilityIP')}</p>
                    <p className="w-utility-item"><span>🤫</span>{t('nftPage.utilityMedia')}</p>
                    <p className="w-utility-item"><span>🚀</span>{t('nftPage.utilityMore')}</p>
                    <div className="w-text-center w-full-width m-t-4">
                        <a target="_blank" rel="noreferrer" className="w-discord-button"
                           href="https://discord.gg/P5wBaFvWDp">{t('common.joinDiscord')}</a>
                    </div>
                </div>

                <OfficialNFTMintForm setNotify={setNotify}/>
            </div>

            <div className="w-scroll-arrow">
                <div onClick={() => scroll('nft-carousel')}>
                    <ExpandMoreIcon className="w-icon hoverable moving large"/>
                </div>
            </div>

            <h1 id="nft-carousel" className="w-main-page-header m-t-4 p-t-2 w-no-spacing-bottom"
                style={{marginBottom: -20}}>{t('nftPage.headingNftPreview')}</h1>
            <div className="w-carousel-container w-section w-clouds-background waves-5">

                <OfficialNFTCarousel/>
            </div>

            {/* TODO: or just show button to switch to dashboard later (bridge NFTs, show NFTs, vote snapshot, utilities etc.)! */}
            {/*<div className="w-section w-clouds-background waves-5" style={{'marginTop': '10rem', 'display': 'none'}}
                 id="dashboard-area">
                {nftBalance?.gt(0) && <OfficialNFTDashboardView setNotify={setNotify}/>}
            </div>*/}

        </Box>
    </div>
})
