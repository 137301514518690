import { Buffer } from "buffer/";
window.Buffer = window.Buffer || Buffer; // needed since webpack 5 doesn't support buffer


// TODO: Add signatures with addresses

// Whitelist addresses (here to avoid hosting a backend, but still saving gas on smart contract side) - disadvantage: privacy
const signatureMap = new Map<string, string>([
    ['0x81C2016c97970752080554d0Ed365717D597076C'.toLowerCase(), '0x75e80494e5ab2a06bba385e7ecaa9824936488d645a9bf154da59d1267d56b982a3b3dcf01c757bb2f60630fd6ac342921d98cb9e74b4de24b2f7e6cbc4813071c'],
    ['0x27D9c7fECB35f416687330b060eD05e90d0Ed40b'.toLowerCase(), '0xd2319b7d0ec03a217504690d21badf8ceba5ec46aa01332716c7e7c8081dcb1e741d7f1b5385d6763b96fd5527fa2335115e4a4d3173a32e8ae0e41cda07a0931b'],
    ['0x7Bd773e37b85ef9c5f7df7622d399474026a7Ad5'.toLowerCase(), '0xb6db8242b367f2f2b3660b0ec511191f8559da452466f7b855b7dc927ab3403052cd8e6e699ead0666385e1ec4131151ba8358e7db4ecd9716c1cef9c63627651b'],
    ['0xA535CC4FD7E2A424f06f4a25A479c649a3C24c5E'.toLowerCase(), '0xaa408e97c2f7624d5986e90da34a4e9e010ed5d3dad5726aac31100d6fb7431d29880f3f0d4fb6cf484cd8329b8607422b4b243cbb2287b5630bf691318fe65b1b'],
    ['0xC80aCEdbCc07c951E8cB783151Fcae6cd6833644'.toLowerCase(), '0x03fbf3b329915045d29631dba3a2ace9a3c631964a285333a0f6ca887bf0b4630ed43ab2e3ec711c65ed62a5be4398da44aceed8032a72ffeeac235f1a2401081c'],
    ['0x6416E16212C874De57Ac336a0eA674F9B2CAfC23'.toLowerCase(), '0x8618f9f25b6a3abd01b112ba912560b4173ddcd83f788d21668c4d366e24d87e69341b36370607de0fcf48ee57c18bebe23187c53058d25c7b1ce674c013929e1c'],
    ['0x862687EafbA7a988148Ef563F830E8B66fdDFD8b'.toLowerCase(), '0x00b6061c5453501e105a4e25539c7671f6cf458b14d6fad67a8d91f0a91e55d6394b41ad80210a8e54692d8cf46767d75c13fe007fdd332b1e060c597d9f6c181c'],
    ['0xa5046Aef4Ee8F0481A9B160300453f1BDF6Eb268'.toLowerCase(), '0x61e1b707e897ecb59ed85fbfec7791a9632c403271d0a05d8963a02b4d4bee485ff05bef9b00d776e018046ecd3362718004c27514f39ea00ce524f6439f70a41c'],
    ['0x412177FE65f6384D7fE3280C5580493246856E55'.toLowerCase(), '0x3f5b0c3d1284707a3a968c62a08d9f1f4c34e481b85f6fdad117d4cbe7edfb270fdfe6b2c2f6b38900abc98fdbd8563f93b60e1535367ec21065897b801560a21b'],
    ['0xd0765882d15Dbb3D88d3F2a903d6AE3B881d5059'.toLowerCase(), '0xafba3c74996b3b16976facc0fd2c47b44f3a5103aa3457591f2558593a8f18665c2e77c8573af0e9460e495c94dbd7de10100fb489e3ed143465f4ed9742497f1b'],
    ['0xf01726E8820456F2005b8D5d57f757b6F527a6C7'.toLowerCase(), '0x946bdd065e42d5f22ff9ad52a1fbceeeb63030f1cce74c49cc61cc4f4e96a0ef6a39b4a0244141321677e8500bd2ecbc3b6e1db008631214f6d9b7440782a9ab1c'],
    ['0xCD1465c922DA670a1979396ac00A7cc5362372a5'.toLowerCase(), '0x08bb24f1ebc0fc27c9240708e356e72a1f921f693983d619cbe074b1fecbdb3a42d87ce85e5eb548b442a664d1baf33aeff00828468b4888a499ece7623446ea1c'],
    ['0x468Ac1fb57bB232639A4759e477D9a2e1E00e3a0'.toLowerCase(), '0x096cf54d9fc0ae050cee366c21a347e51239a50752ceb7ade5c7ad40b43615ed36c583113c09816b9312adc2e9e7da63e8a150761c194a1ea7f63b874d910c031c'],
// TODO: addresses lowercase!
])

const EMPTY_SIG = '0x'

/** @returns Empty array if no address provided or if address is not in whitelist */
export const getOfficialNFTSignature = (address: string|undefined): string => {
    if (!address) return EMPTY_SIG;
    return signatureMap.get(address.toLowerCase()) ?? EMPTY_SIG;
}
