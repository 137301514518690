import {useLookupAddress} from "@usedapp/core";
import {WPrettyAddressInterface} from "./WPrettyAddress.interface";

export const WPrettyAddress = (props: WPrettyAddressInterface) => {
    const {address} = props;
    const {ens, isLoading, error} = useLookupAddress(address) // TODO: might only work for Mainnet? To try
    return (
        <div>
            {ens ?? `${address.substring(0, 2)}...${address.substring(address.length - 4)}`}
        </div>
    )
}
