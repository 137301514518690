import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {WSwitchNetworkDialogInterface} from "./WSwitchNetworkDialog.interface";
import styles from "../../../wavect-styles/_export.module.scss";
import {useEthers} from "@usedapp/core";
import {Box, Chip, Tab, Tabs} from "@mui/material";
import {
    supportedMainnets,
    supportedTestnets
} from "../../../../contracts/contract-addresses";
import {getTabProps, WTabPanel} from "../WTabPanel/WTabPanel";
import {t} from "i18next";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const WSwitchNetworkDialog = (props: WSwitchNetworkDialogInterface) => {
    const {switchNetwork} = useEthers()
    const [tabIdx, setTabIdx] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdx(newValue);
    };

    const handleCancel = () => {
        props.setShow(false);

        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <div>
            <Dialog
                open={props.show}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancel}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{t('networkSwitchDialog.heading')}</DialogTitle>
                <DialogContent>
                    <div>
                        {props.currentChainSupported ? t('networkSwitchDialog.ctaMsgSwitch') : t('networkSwitchDialog.ctaMsgUnsupportedSwitch')}
                        <br/><br/>
                        <Box sx={{borderBottom: 1, borderColor: 'divider', width: 'fit-content', display: 'flex', justifyContent: 'center'}}>
                            <Tabs
                                value={tabIdx}
                                onChange={handleTabChange}
                                aria-label="Network switch tabs"
                                textColor="secondary"
                                indicatorColor="secondary">

                                <Tab label={t('networkSwitchDialog.tabMainnet')} {...getTabProps(0)} />
                                <Tab label={t('networkSwitchDialog.tabTestnet')} {...getTabProps(1)} />
                            </Tabs>
                        </Box>
                        <WTabPanel value={tabIdx} index={0}>
                            {supportedMainnets.map((newChain, i) => {
                                return <Chip variant="outlined" className="animatedChip" color='secondary'
                                             style={{marginRight: 5}}
                                             key={i} label={newChain.chainName}
                                             onClick={async () => {
                                                 await switchNetwork(newChain.chainId);
                                                 props.setShow(false); // close modal
                                             }}/>
                            })}
                        </WTabPanel>
                        <WTabPanel value={tabIdx} index={1}>
                            {supportedTestnets.map((newChain, i) => {
                                return <Chip
                                    variant="outlined" className="w-chip" color='secondary'
                                    style={{marginRight: 5, height: 40, padding: 5}}
                                    key={i}
                                    label={newChain.chainName}
                                    onClick={async () => {
                                        await switchNetwork(newChain.chainId);
                                        props.setShow(false);
                                    }}/>
                            })}
                        </WTabPanel>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} style={{'color': styles.accent}}>{t('networkSwitchDialog.close')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
