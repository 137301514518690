import {useContractFunction} from "@usedapp/core";
import {useContract} from "../../hooks/useContract";
import {ContractNames} from "../../contracts/contract-addresses";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {isTxLoading} from "../../utils/transactions";
import {IOfficialNFTMintBtnProps} from "./IOfficialNFTMintBtn.props";
import {memo} from "react";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {t} from "i18next";
import WButton from "../../shared-libs/wavect-core/components/WButton/WButton";

export const OfficialNFTMintBtn = memo((props: IOfficialNFTMintBtnProps) => {
    const officialNFTContract = useContract(ContractNames.OfficialNFT);
    const {isAllowedToMint, mintPriceWei, signature, account, amount, setNotify} = props

    const {
        state: mintState,
        send: mintNFT,
        events: mintEvents
    } = useContractFunction(officialNFTContract, "mint", {transactionName: "mint"}); // maybe add static ETH/Goerli chainId again

    if (mintState.status === "Fail") {
        // Note: Exception state is seemingly only user abortion, etc.
        setNotify({msg: 'Minting failed, please contact us on Discord.', severity: 'error'})
    }
    if (mintEvents) {
        const mintEvent = mintEvents?.find(e => e.eventFragment.name === 'Transfer');
        const mintedTokenId = mintEvent?.args[2];
        if (mintedTokenId) {
            setNotify({msg: `Successfully minted NFT with ID ${mintedTokenId}`, severity: 'success'})
        }
    }

    return <>
        <WButton className="m-t-2 m-b-2 w-btn-mint-wallet" colorType="dark" onClick={() => mintNFT(account, signature, amount, {value: mintPriceWei})} disabled={!isAllowedToMint}>
            { isTxLoading(mintState.status) ? <><CircularProgress sx={{marginRight: '1rem', color: 'white'}} size='2vh'
                                                                            disableShrink/> {mintState.status}</>
                : <><ShoppingBagIcon/>&nbsp;{t('nftPage.btnWalletMint')}</>}
        </WButton>
      </>
})
