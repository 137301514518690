import {createTheme} from "@mui/material";
import styles from "../_export.module.scss";

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: styles.primary,
            light: styles.primaryLight,
            contrastText: styles.textAccent,
        },
        secondary: {
            main: styles.secondary,
            light: styles.secondaryLight,
            contrastText: styles.textAccent,
        },
        error: {
            main: styles.danger,
            light: styles.dangerLight,
            contrastText: styles.textAccent,
        }
    },

});