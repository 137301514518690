import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from 'react-i18next';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ernesto from "../shared-libs/wavect-styles/assets/artwork/ernesto.png";
import videoBackground from "../shared-libs/wavect-styles/assets/background/video-thumbnail.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WButton from "../shared-libs/wavect-core/components/WButton/WButton";
import {WShowMore} from "../shared-libs/wavect-core/components/WShowMore/WShowMore";

interface EducationRegisterEntry {
    id?: number;
    title: string;
    date: { en: string, de: string };
    content: string;
    image: string;
    buttonText: { en: string, de: string };
    buttonAction: { en: string, de: string };
    otherLanguageAvailable: boolean;
    // just hide message
    dontShowAltLanguage?: boolean;
    searchingForSponsor: boolean;
}

export default function EducationalPage() {
    const {t, i18n} = useTranslation();

    const [ernestoTalk, setErnestoTalk] = React.useState('');

    const scroll = (id: 'social-section' | 'register-section' | 'educational-page') => {
        const section = document.querySelector('#' + id);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };


    const ernestoSpeak = [
        t('ernestoSpeaks.one'),
        t('ernestoSpeaks.two'),
        t('ernestoSpeaks.three'),
        t('ernestoSpeaks.four'),
        t('ernestoSpeaks.five'),
        t('ernestoSpeaks.six'),
        t('ernestoSpeaks.seven'),
        t('ernestoSpeaks.eight'),
        t('ernestoSpeaks.nine'),
        t('ernestoSpeaks.ten'),
    ]

    const socials = [
        {
            link: 'https://www.linkedin.com/company/wavect/',
            icon: <LinkedInIcon className="w-icon-social"/>
        },
        {
            link: 'https://anchor.fm/wavect',
            icon: <PodcastsIcon className="w-icon-social"/>
        },
        {
            link: 'https://github.com/wsdt',
            icon: <GitHubIcon className="w-icon-social"/>
        },
        {
            link: 'https://www.tiktok.com/@wavect.io',
            icon: <MusicNoteIcon className="w-icon-social"/>
        },
        {
            link: 'https://twitter.com/Wavect_eth',
            icon: <TwitterIcon className="w-icon-social"/>
        },
        {
            link: 'https://www.instagram.com/wavect.io/',
            icon: <InstagramIcon className="w-icon-social"/>
        },
        {
            link: 'https://www.youtube.com/wavect',
            icon: <YouTubeIcon className="w-icon-social"/>
        },
        {
            link: 'https://www.facebook.com/wavect/',
            icon: <FacebookIcon className="w-icon-social"/>
        }
    ]


    const showLetterByLetter = () => {
        const text: string = ernestoSpeak[Math.floor(Math.random() * ernestoSpeak.length)];
        let pos = 0;
        let currText: string = '';

        let interval = setInterval(() => {
            if (pos + 1 >= text.length) clearInterval(interval);
            currText += text[pos];

            setErnestoTalk(currText);
            ++pos;
        }, 75)
    }

    useEffect(() => {
        showLetterByLetter();
    }, [])

    const coursesToRegister: EducationRegisterEntry[] = [
        // depending on what language is chosen, a different typeform will be loaded
        /*{
            id: 1,
            title: t('courses.web3ForEntries.title'),
            content: t('courses.web3ForEntries.content'),
            image: videoBackground,
            buttonText: {
                en: t('common.registerNow'),
                de: t('common.registerNow'),
            },
            buttonAction: {
                en: '#',
                de: '#',
            },
            date: {
                en: 'Apr 29th 2023 09:30 am CET - 03:30 pm',
                de: '15. April 2023, 9:30 - 15:30 CET'
            },
            otherLanguageAvailable: true,
            searchingForSponsor: true,
        },*/
        {
            id: 1,
            title: t('courses.web3ForEntries.title'),
            content: t('courses.web3ForEntries.content'),
            image: videoBackground,
            buttonText: {
                en: 'Book now',
                de: 'Hier buchen',
            },
            buttonAction: {
                en: 'https://form.jotform.com/233475316574360',
                de: 'https://form.jotform.com/233475316574360',
            },
            date: {
                en: '02 Mar 2024, 10 am',
                de: '17.02.2024 10:00 Uhr'
            },
            otherLanguageAvailable: true,
            dontShowAltLanguage: true,
            searchingForSponsor: true,
        },
    ]

    return (
        <div className="" id="educational-page">
            {/** Starter Section: Ernesto & Intro to Courses */}
            <div className="w-section w-clouds-background waves-7 w-center-center column starter">
                <div className="w-ernesto-intro">
                    <div className="bubble bubble-bottom-left">{ernestoTalk}</div>
                    <a href="https://twitter.com/ErnestoWavect" target="_blank" rel="noreferrer"
                       title="Follow Ernesto on Twitter">
                        <img className="w-img-with-shadow" src={ernesto} alt="Ernesto - Chief Meme Officer, Wavect"
                             width={256}/>
                    </a>
                </div>

                <div className="w-educational-page-header">
                    <h1 className="w-text-center">{t('common.upcomingEvents')}</h1>
                    <p className="w-text-center">{t('registration.intro')}</p>
                    <p className="w-text-center">{t('registration.content')}</p>
                </div>

                <a className="primary" target="_blank" href="https://calendly.com/wavect/consultation-session"
                   rel="noreferrer">
                    <WButton colorType='secondary'>
                        <CalendarMonthIcon/>&nbsp;
                        {t('common.ctaBusinessCall')}</WButton>
                </a>

                <WShowMore click={() => scroll('register-section')} text={t('common.seeMore')}></WShowMore>
            </div>

            {/** Course Section: Show currently available courses */}
            <div className="w-section  w-clouds-background waves-4 m-t-6" id="register-section">
                <div className="w-center-center w-row" id="video-area">
                    {
                        coursesToRegister.map(course => (
                            <div className="w-register m-3">
                                <img src={course.image} alt=""/>
                                <h1 className="w-video-register-header p-t-4 m-t-1">{course.title}</h1>
                                <p className="w-video-register-date">🗓️ {i18n.resolvedLanguage === 'en' ? course.date.en : course.date.de}</p>
                                <hr className="w-hr m-t-2"/>

                                <div className="text">
                                    <p className="w-video-register-content">
                                        {course.content}
                                    </p>
                                </div>

                                {course.dontShowAltLanguage ? null :
                                    course.otherLanguageAvailable ?
                                        <a target="_blank" rel="noreferrer" className="m-2 w-text-center white"
                                           href={i18n.resolvedLanguage === 'en' ? course.buttonAction.de : course.buttonAction.en}>👉 <span>{t('common.registerNowOtherLanguage')} </span></a>
                                        :
                                        <p className="m-2 w-text-center white">{t('common.registerNoOtherLanguage')} </p>
                                }

                                <div className="w-register-btn-container">
                                    <a className="w-register-button w-register-btn-left left" target="_blank"
                                       rel="noreferrer" key="register-btn"
                                       href={i18n.resolvedLanguage === 'en' ? course.buttonAction.en : course.buttonAction.de}>{i18n.resolvedLanguage === 'en' ? course.buttonText.en : course.buttonText.de}</a>
                                    <a className="w-register-button w-register-btn-right" target="_blank"
                                       rel="noreferrer" key="sponsor-btn"
                                       href="https://calendly.com/wavect/sponsoring-exploration">{t('common.becomeSponsor')}</a>
                                </div>
                            </div>
                        ))
                    }

                    <div className="w-more-information p-l-6" style={{'maxWidth': '700px'}}>
                        <h2>{t('common.moreInformation')}</h2>
                        <p className="w-text-start">{t('registration.explanation')}</p>
                        <p className="w-text-start">{t('registration.watchMore')}</p>
                        <div className="w-center-center">
                            <a target="_blank" className="w-discord-button" href="https://discord.gg/P5wBaFvWDp"
                               rel="noreferrer">
                                Join Discord Channel</a>
                        </div>
                    </div>
                </div>
                <h2 className="w-social-container-header w-text-center">{t('common.ourSocials')}</h2>
                <div className="w-social-container">
                    {socials.map((item, i) => (
                        <div className="w-social-row">
                            <IconButton disableRipple={true} className="w-icon-button" aria-label="Linkedin.com"
                                        onClick={() => window.open(item.link)}>
                                {item.icon}
                            </IconButton>
                        </div>
                    ))}
                </div>
                <div className="w-mobile-bottom-info w-center-center" style={{'display': "none"}}>
                    <div className="m-t-4">
                        <ExpandLessIcon onClick={() => scroll('educational-page')} className="w-icon large"/>
                    </div>
                    <span className="back-to-start">{t('common.backToTop')}</span>
                </div>
            </div>
        </div>
    );
}
