import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import {Link} from "react-router-dom";
import {List, ListItem, ListItemIcon, SwipeableDrawer} from "@mui/material";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import styles from "../shared-libs/wavect-styles/_export.module.scss";
import NotesIcon from '@mui/icons-material/Notes';
import LaunchIcon from '@mui/icons-material/Launch';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {useTranslation} from "react-i18next";
import {getLangISO2} from "../utils/i18n";
import {MetamaskConnect} from "../shared-libs/wavect-core/components/MetamaskConnect";
import whiteLogo from "../shared-libs/wavect-styles/assets/logo/wavect-white-logo.png"
import SchoolIcon from '@mui/icons-material/School';


const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const {t, i18n} = useTranslation();

    const pages = [
        {
            name: t('menu.home'),
            route: '/',
            icon: <SchoolIcon/>,
            nftGated: false,
            isExternalLink: false,
        },
        {
            name: t('menu.collection'),
            route: '/collection',
            icon: <LibraryBooksIcon/>,
            nftGated: false,
            isExternalLink: false,
        },
        // {
        //     name: "Media",
        //     route: '/media',
        //     icon: <OndemandVideoIcon/>,
        //     nftGated: true,
        //     isExternalLink: false,
        // },
        {
            name: t('menu.whoWeAre'),
            route: '/contact',
            icon: <EmojiPeopleIcon/>,
            nftGated: false,
            isExternalLink: false,
        },
        {
            name: "Wavect.io",
            route: 'https://wavect.io',
            icon: <LaunchIcon/>,
            nftGated: false,
            isExternalLink: true,
        }
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenu(true);
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const connected = (account: any) => {
        // console.log(account)
    }

    const list = (menuItems: Array<{ name: string, route: string, icon: any }>) => (
        <Box sx={{width: 250, height: '100%'}} role="presentation" className='w-menu-container'>
            <h3 className="w-no-spacing m-t-4 m-b-4 w-white w-text-center">Welcome to Wavect</h3>
            <hr className="w-hr"/>
            <List key='menu-items'>
                {menuItems.map((item) => (
                    <ListItem key={item.route} disablePadding sx={{marginTop: '1rem'}}
                              onClick={() => setOpenMenu(false)}>
                        <ListItemIcon sx={{color: styles.white, paddingLeft: '2rem'}}>{item.icon}</ListItemIcon>
                        <Link className="menu-link-item" to={item.route}>{item.name}</Link>
                    </ListItem>
                ))}
            </List>

            <hr className="w-hr m-t-6 w-white"/>
            <button className="w-language-switch-drawer"
                    onClick={async () => await switchLanguage()}> {getLangISO2(i18n) === 'EN' ? 'Sprache wechseln' : 'Switch Language'}</button>
            <hr className="w-hr"/>

            <div className="w-mobile-menu-footer"></div>
        </Box>
    );

    function navigateHome() {
        window.location.href = '/';
    }

    async function switchLanguage() {
        if (i18n.language.indexOf('de') !== -1) {
            await i18n.changeLanguage('en')
        } else {
            await i18n.changeLanguage('de');
        }
    }

    return (
        <AppBar position="sticky" sx={{bgcolor: '#307480', position: 'fixed'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 2, display: {xs: 'flex', lg: 'none', justifyContent: "space-between"}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="info">

                            <NotesIcon sx={{fontSize: '40px', color: styles.secondaryLight}}/>
                        </IconButton>
                        {/*<img src={whiteLogo} alt="" width="100"*/}
                        {/*     style={{'position': "absolute", "right": '55%', "top": '18px'}}/>*/}

                        {/* Mobile View */}
                        <SwipeableDrawer
                            sx={{display: 'flex', flexDirection: 'column'}}
                            anchor='left'
                            open={openMenu}
                            onClose={() => setOpenMenu(false)}
                            onOpen={() => true}
                        >
                            {list(pages)}
                        </SwipeableDrawer>
                    </Box>
                    {/*Desktop View*/}
                    <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}}} display="flex"
                         justifyContent="flex-start" alignItems="center">
                        <Box
                            component="img"
                            style={{'cursor': "pointer"}}
                            onClick={() => navigateHome()}
                            sx={{
                                width: 100,
                                height: 30,
                                maxHeight: {xs: 233, md: 167},
                                maxWidth: {xs: 350, md: 250},
                                marginRight: '10rem'
                            }}
                            src={whiteLogo}
                        />
                        {pages.map((page) => (
                            page.isExternalLink ?
                                <a className="w-menu-route-button" href={page.route} target="_blank" key={page.route} rel="noreferrer">
                                    {page.icon}
                                    <span className="p-l-1">{page.name}</span>
                                </a>
                                :
                                <Link className="w-menu-route-button" style={{textDecoration: 'none'}} to={page.route}
                                      key={page.route}>
                                    {page.icon}
                                    <span className="p-l-1">{page.name} {page.nftGated ?
                                        <span className="w-highlight-bordered">NFT</span> : null} </span>
                                </Link>
                        ))}

                    </Box>
                    <button className="w-language-switch m-r-2"
                            onClick={async () => await switchLanguage()}>{i18n.resolvedLanguage}</button>
                    <MetamaskConnect onConnection={(a) => connected(a)}/>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
