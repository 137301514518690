import React, {useState} from "react";
import {Chain, useEthers} from "@usedapp/core";
import Box from "@mui/material/Box";
import {Chip, useMediaQuery} from "@mui/material";
import {MetaMaskConnectProps} from "../models/common";
import {getSupportedChain} from "../../../contracts/contract-addresses";
import {WPrettyAddress} from "./WPrettyAddress/WPrettyAddress";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined';
import styles from '../../wavect-styles/_export.module.scss'
import {WSwitchNetworkDialog} from "./WSwitchNetworkDialog/WSwitchNetworkDialog";
import {t} from "i18next";
import WButton from "./WButton/WButton";

/*const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});*/

export const MetamaskConnect = (props: MetaMaskConnectProps) => {
    const {account, deactivate, activateBrowserWallet, chainId} = useEthers();
    const isConnected = account !== undefined;
    const supportedChain: Chain|undefined = getSupportedChain(chainId ?? -1)
    const [showSwitchNetworkDialog, setShowSwitchNetworkDialog] = useState(false);
    const chainName: string = supportedChain?.chainName ?? '';

    const isMobile = useMediaQuery(`(max-width: 410px)`);

    const connect = async () => {
        activateBrowserWallet();
        props.onConnection(account)
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
        >
            {isConnected ?
                <>
                    <WButton className="w-pretty-address-root" colorType={supportedChain ? 'dark' : 'danger'} ignoreNetworkDisabled={true}
                             onClick={ () => supportedChain ? deactivate() : setShowSwitchNetworkDialog(true)}>
                        {supportedChain ? <WPrettyAddress address={account}  />
                            : <><PublicOffOutlinedIcon sx={{marginRight: '0.2rem'}} />{t('common.wrongNetwork')}</>}
                    </WButton>
                    {supportedChain ?
                        <Chip sx={{'background': styles.secondaryLight, 'color': 'white'}}  className="w-chip" label={chainName} onClick={() => setShowSwitchNetworkDialog(true)}/> : ''}
                </>
                : <WButton colorType="secondary" className="w-connect-wallet-button" onClick={async () => await connect()} ignoreNetworkDisabled={true}>
                    <AccountBalanceWalletOutlinedIcon sx={{marginRight: '0.5rem'}}/>
                    {isMobile ? null : t('common.connect')}
                </WButton>
            }

            <WSwitchNetworkDialog
               currentChainSupported={!!supportedChain}
                show={showSwitchNetworkDialog}
                setShow={setShowSwitchNetworkDialog} />
            <br/><br/>
        </Box>
    )
}
