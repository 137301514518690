import styles from "../_export.module.scss";
import {ColorType} from "../../wavect-core/components/WButton/WButton.interface";


/**
 * can be used in many components, determines which style to be applied.
 * any Wavect component can set a colorType.
 */
export const getColorType = (colorType: ColorType) => {
    switch (colorType) {
        case 'primary': {
            return styles.primaryLight;
        }
        case 'danger': {
            return styles.danger;
        }
        case 'secondary': {
            return styles.secondary
        }
        case 'dark': {
            return styles.dark;
        }
        default: {
            return styles.accent;
        }
    }
}
