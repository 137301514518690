import {Arbitrum, BSCTestnet, Chain, Goerli, Mainnet} from "@usedapp/core";
import {isDevelopmentBuild} from "../utils/build-flavor";

export const supportedTestnets: Chain[] = []
export const supportedMainnets: Chain[] = [Arbitrum]
export const supportedChains = [...supportedMainnets, ...supportedTestnets]
export const getSupportedChain = (chainId: number): Chain|undefined => supportedChains.find(c => c?.chainId === chainId)

/** @dev Will be used if no chainId is given OR for non-multichain contracts such as the NFT itself (mainChain = ETH and Ropsten) */
export const getRecommendedChainId = () => (isDevelopmentBuild() ? supportedTestnets[0]?.chainId : supportedMainnets[0]?.chainId) ?? supportedChains[0].chainId;

interface IContractAddresses {
  [contractName: string]: {
    [chainId: number]: string
  }
}

/** @dev Note that your ABI files need to be named exactly that way as well! */
export const ContractNames = {
  OfficialNFT: 'OfficialNFT',
}

// NOTE: Since ETH mainnet is the default chain (on page load) we need to provide at least a dummy address to not make the page crash for now
const addresses: IContractAddresses = {
  [ContractNames.OfficialNFT]: {
    [Arbitrum.chainId]: "0xa4a6ad0546363d093a6eef1eabd31255a49b7635",
    // TODO: Add polygon zkevm once ready to mint
  },
}

export default addresses;
